//** Custom Font Family.
$font-roboto:             "Roboto regular", Arial, sans-serif;
$font-roboto-bold:        "Roboto Bold", Arial, sans-serif;

$font-montserrat:         "Montserrat", Arial, sans-serif;
$font-montserrat-bold:    "Montserrat Bold", Arial, sans-serif;

//** Custom Colors.
$white:        #fff;
$black-59:     #595959;
$black-3b:     #3b3c3e;
$purple:       #343565;

$blue-19:      #343565;
$blue-f3:      #f3f3f5;
$blue-1e:      #1e7493;
$green-2b:     #343565;
$green-f6:     #f68424;
$orange-f5:    #343565;
$yellow-f7:    #343565;
$yellow-f7c:   #5dbc56;
$red-ef:       #ef4d5a;
$grey-81:      #818284;
$grey-be:      #bebec0;
$grey-94:      #949599;
$red-ef:       #ef4d58;
$red-f0:       #f00818;
$blue:         #24babd;
$green:        #68a924;
$green-2c:     #178a53;
$no-radius:    0px;

/*OLD COLORS
$blue-19:      #009fcf;
$blue-f3:      #f3f3f5;
$blue-1e:      #1e7493;
$green-2b:     #f3c837;
$green-f6:     #f68424;
$orange-f5:    #f58233;
$yellow-f7:    #343565;
$yellow-f7c:   #5dbc56;
$red-ef:       #ef4d5a;
$grey-81:      #818284;
$grey-be:      #bebec0;
$grey-94:      #949599;
$red-ef:       #ef4d58;
$red-f0:       #f00818;
$blue:         #24babd;
$green:        #68a924;
$green-2c:     #178a53;

 */





$text-color:            $black-59 !default;
$link-color:            $white !default;
$link-hover-color:      $green-2b !default;
$font-family-base:      $font-roboto !default;
$btn-default-color:     $green-2b !default;
$btn-default-bg:        $white !default;
$btn-primary-bg:        $blue-19 !default;
$btn-success-bg:        $orange-f5 !default;
$btn-warning-bg:        $green-2b !default;
