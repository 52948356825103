.vehicle-details{
  margin-left: -15px;
  margin-right: -15px;
}

.navbar .page-name {
  text-transform: uppercase;
}
.car-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  li{
    &:before{
      background: #e8e8e8;
      bottom: 0;
      content: "";
      height: 60%;
      position: absolute;
      right: 0;
      top: 145px;
      width: 2px;
    }
    h3{
      height: 50px;
    }
    p{

      a:hover{
        color : $red-ef;
      }
    }
    &.clearfix{
      padding: 0;
      margin: 0;
    }

  }
  &.services{
   li{
     p{

     }
     figure.mt50{
       height: 55px;
     }
   }

  }
  &.automovel{
    li{
      p{

      }
      &.secound-row{
        p{

        }
      }
      &.third-row{
        p{

        }
      }
    }
  }
}

.equipment-list{
  li{
    font: 500 17px/28px "Roboto regular",Arial,sans-serif;
  }
}

.car-list li:after{
  background: #e8e8e8;
  bottom: 0;
  content: none!important;
  height: 60%;
  position: absolute;
  right: 0;
  top: 145px;
  width: 2px;
}
.car-list{
  a{

    color : #333;
  }
  .btn{
    line-height: 31px;
  }
}

.equip-content{
  a{
    color : #333;
    &:hover{
      color :$red-ef;
    }
  }
  a.btn{
    line-height: 31px;
    &:hover{
      color : white;
    }
  }
}
.go-up-button{
  height: 50px;
  width: 50px;
  background-color: rgba($red-ef , 1);
  text-align: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  i{
    font-size: 20px;
    line-height: 50px;
    color : white;
  }
}

.hipo-contacts , .info-wrapper{
  a{
    color:inherit;
    &:hover{
      color:$red-ef;
    }
  }
}



button{
  &.menu-toggle{
    outline: none!important;
    position: relative;
    .hamburguer{
      transition: all ease 0.5s;
      transform: rotate(0deg);
      opacity: 1;
    }
    .close-menu{
      transition: all ease 0.5s;
      position: absolute;
      top : 50%;
      left:50%;
      transform: translate(-50% , -50%) rotate(180deg);
      opacity: 0;
    }
    &.open{
      .close-menu{
        transform: translate(-50% , -50%) rotate(0deg);
        opacity: 1;
      }
      .hamburguer{
        transform: rotate(-180deg);
        opacity: 0;
      }
    }
  }
}


.footer-logo {
  width: 30px;
}

.vehicle-details > li ol li {
    color: #333;
}

.footer ul li ul li a {
  display: block;
}

.navbar-nav>li>a:after{
  position: absolute;
  height: 2px;
  width: 100%;
  background: transparent;
  content: '';
  left: 0;
  bottom: 0;
  transition: all .5s linear;
}

.banner-slider .carousel-caption h4 {
  font: 40px "Montserrat",Arial,sans-serif;
  color: #fff;
  margin-bottom: 32px;
}



.info-wrapper{
  .row{
    margin: 76px 0 0px;
  }
  p{
    font: 500 17px/28px "Roboto regular",Arial,sans-serif;
    margin-bottom: 24px;
    margin-left: 50px;
  }
  figure {
    position: relative;
    padding-right: 50px;
    margin-right: 28px;
  }

  a.btn{
    color : white;
    line-height: 31px;
    margin-left: 50px;
  }
}

a.btn{
  line-height: 31px;
}

.vehicle-details{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  & >li .info-bg {
    &:hover{
      h3{
        &:after{
          width: 90%;
        }
      }
    }
    h3{
      position: relative;
      &:after{
        transition: all ease 0.3s;
        position: absolute;
        width: 0%;
        height: 2px;

        bottom: -10px;
        left:0;
        content: '';
      }
    }

    position: relative;
    height: 100%;
    & > a{
      display: block;
      height: 100%;
    }
    .vehicle-img {
      height: 100%;
      .vehicle-icon{
        position: absolute;
        top: 0;
        right: -14px;
        width: 45px;
        height: 45px;
        padding: 0px;
        img{
          padding-top: 10px;
        }
      }
    }
  }
  .automobile{
    .info-bg{
      h3{
        position: relative;
        &:after{
          background-color: $blue-19;

        }
      }
    }
    .btn.vehicle-icon{
      background-color: $blue-19;
      border-color: $blue-19;
    }
  }
  .services{
    .info-bg{
      h3{
        position: relative;
        &:after{
          background-color: $green-2b;
        }
      }
      .btn.vehicle-icon{
        background-color: $green-2b;
        border-color: $green-2b;
      }
    }

  }
  .imobiliaria{
    .info-bg{
      h3{
        position: relative;
        &:after{
          background-color: $purple;
        }
      }
    }
    .btn.vehicle-icon {
      background-color: $orange-f5;
      border-color: $orange-f5;
      &:hover{
        background-color: $orange-f5;
      }
    }
  }
  .food{
    .info-bg{
      h3{
        position: relative;
        &:after{
          background-color: $yellow-f7;
        }
      }
    }
    .btn.vehicle-icon{
      background-color: $yellow-f7;
      border-color: $yellow-f7;
    }
  }
}


.carousel-indicators{
  max-width: 40px;
  margin-left: -20px;
  li{
    width: 15px;
    height: 15px;
    &.active{
      width: 15px;
      height: 15px;
    }
  }
}

.carousel-inner>.item{
  overflow: hidden;
}
section {
  position: relative;
}
.archor-point{
  position: absolute;
  width: 0px;
  height: 0px;
  top:-50px;
  left:0px;
}

#mapa1{
  height: 500px;
}

.brand-image-wrapper{
  transition: all ease 0.5s;
  box-shadow: 0 0px 0px transparent, 0 1px 2px transparent;
  &:hover{
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}


.main-header a{
  > ul.navbar-nav{
    li{
      .dropdown-menu{
        min-width: 130px;
        li{
          a{
            font-size: 12px;
          }
        }
      }
    }
  }
}

.banner-slider {
  .carousel-caption {
    h4{

    }
  }
  .automovel{
    a.btn{
      background-color:$blue-19;
      border : $blue-19;
    }
  }
  .services{
    a.btn{
      background-color: $green-2b;
      border : $green-2b;
    }
  }
  .alimentar{
    a.btn{
      background-color: $yellow-f7;
      border : $yellow-f7;
    }
  }
  .imobiliaria{
    a.btn{
      background-color: $orange-f5;
      border : $orange-f5;
    }
  }
}



.go-up-button{
  background: rgba(0,0,0,0.2);
}






