/*body {
    background-color:white;
    color: $secundary-color;
    font-family: 'Montserrat', sans-serif;
}

section {
    margin-bottom: 40px;
}

h1 {
    color: $primary-color;
    font-size: 48px;
    line-height: 58px;
    font-family: 'Montserrat', sans-serif;
    margin:35px auto;
}

h2 {
    color: $secundary-color;
    font-size: 24px;
    line-height: 34px;
    font-family: 'Montserrat', sans-serif;
    margin:10px auto;
}

h3 {
    color: $secundary-color;
    font-size: 24px;
    line-height: 34px;
    font-family: 'Montserrat', sans-serif;
    margin:10px auto;
}

h4{
    color: $secundary-color;
    font-size: 24px;
    line-height: 34px;
    font-family: 'Montserrat', sans-serif;
    margin:10px auto;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 24px;
}

.active{
    text-transform: uppercase;
}*/

/* Load Spinner */
.load {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: $blue; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, $blue, $green); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, $blue, $green); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, $blue, $green); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, $blue, $green); /* Standard syntax */
    overflow:hidden !important;
}

.sk-folding-cube {
    width: 60px;
    height: 60px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
    transform: translate(-50%, -50%);
    top: 40%;
    left: 50%;
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); 
}

.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; 
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0; 
    } 25%, 75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0; 
    } 
}

@keyframes sk-foldCubeAngle {
    0%, 10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0; 
    } 25%, 75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0; 
    }
}
