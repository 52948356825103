/*-- Header
================= --*/
.navbar {
	border:0px;
	li {
		font:15px $font-montserrat;
		vertical-align:middle;
		color:$white;
		&.active a {
			color:$green-2b;
		}
	}
	.page-name {
		font-size:16px;
	}
}

.navbar .navbar-default{
	background: $purple !important;
}

.navbar-nav > li > a {
	transition:all .5s linear;
	&:hover {
		color:$green-2b;
		&:after {
			position:absolute;
			height:2px;
			width:100%;
			background:$green-2b;
			content:'';
			left:0;
			bottom:0;
		}
	}
}


.navbar{
	.main-header{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
	}


	.menu-toggle{
		margin-top: 0!important;
	}


}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	color:#000;
}

.navbar-nav > li > .dropdown-menu {
	margin-top:6px;
	padding:5px;
	right:0;
	left:auto;
	border:0px;
	box-shadow:0px 1px 5px #ddd;
	&:after {
		border-bottom: 8px solid #fff;
		border-left: 8px solid rgba(0, 0, 0, 0);
		border-right: 8px solid rgba(0, 0, 0, 0);
		position: absolute;
		right: 0;
		top: -6px;
		content: "";
	}
}

.main-header {
	button {
		background:none;
		border:none;
		margin-top:13px;
		padding:0px;
		&:hover, &:focus {
			background:none;
		}
	}
	.head-logo {
		padding:10px;
		margin:0px 15px;
	}
	a {
		font-size:17px;
	}
}

.header {
	background:rgba(64, 64,65,0.7);
	border: 1px solid rgba(121, 126, 125, .5);
	display:none;
	ul {
		float:none;
		li  {
			display:inline-block;
			float:none;
			margin:0px 20px;
			a {
				&:hover, &:focus {
					color:$white;
					background:none;
					&:after{
						background: $white;
					}
				}
			}
		}
	}
}

.carousel-indicators {
	margin-left:-40px;
	max-width:80px;
	bottom:40px;
	left:50%;
	 li {
	 	background-color:$white;
		border:none;
	 	width:25px;
		height:23px;
		border-radius:0;
		margin:0 0.7px;
		&.active {
			background-color:$red-ef;
			width:25px;
			height:23px;
			margin:0 0.7px;
		}
		&.slide1.active {
			background-color:$blue-19;
		}
		&.slide2.active {
			background-color:$green-2b;
		}
		&.slide3.active {
			background-color:$yellow-f7;
		}
		&.slide4.active {
			background-color:$orange-f5;
		}
	 }
}
