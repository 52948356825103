/*-- Embed Fonts
================= --*/
@font-face {
    font-family:'Roboto regular';
    src:url('fonts/Roboto-Regular.eot');
	src:url('fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto-Regular.woff') format('woff'),
        url('fonts/Roboto-Regular.ttf') format('truetype'),
        url('fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight:normal;
    font-style:normal;
}
@font-face {
    font-family:'Roboto Bold';
    src:url('fonts/Roboto-Bold.eot');
	src:url('fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Roboto-Bold.woff') format('woff'),
        url('fonts/Roboto-Bold.ttf') format('truetype'),
        url('fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight:normal;
    font-style:normal;
}
@font-face {
    font-family:'Montserrat';
    src:url('fonts/Montserrat-Regular.eot');
	src:url('fonts/Montserrat-Regular?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Regular.woff') format('woff'),
        url('fonts/Montserrat-Regular.ttf') format('truetype'),
        url('fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight:normal;
    font-style:normal;
}
@font-face {
    font-family:'Montserrat Bold';
    src:url('fonts/Montserrat-Bold.eot');
	src:url('fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Bold.woff') format('woff'),
        url('fonts/Montserrat-Bold.ttf') format('truetype'),
        url('fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight:normal;
    font-style:normal;
}