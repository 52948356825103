body {
  font-family: "Roboto regular", Arial, sans-serif;
}

a {
  color: $white;
}

a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}

a, .btn {
  @include hover-transition(0.3s, linear);
}

.navbar-default {
  background-color: #404041 !important;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: $green-2b;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #303030;
}

/*-- Common Styles
===================== --*/
.mar0 {
  margin: 0px;
}

.mt65 {
  margin-top: 65px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb50 {
  margin-bottom: 50px;
}

.pad0 {
  padding: 0px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.btn {
  border-radius: 0px;
}

.btn-sai {
  min-width: 177px;
  min-height: 45px;
  font: 16px $font-montserrat;
}

.page-servicos-list {
  .car-list .btn-sai {
    color: $green-2b;
  }
}

.info-bg {
  background: $blue-f3;
}

.navbar {
  border: 0px;
}

.text-g {
  color: $green-f6 !important;
}

/*-- Button Styles
==================== --*/
.btn-blue {
  background: $blue-19;
  color: $white;
  &:hover {
    background: $blue-1e;
    color: $white;
  }
}

.btn-red {
  background: $red-ef;
  color: $white;
  &:hover {
    background: $red-f0;
    color: $white;
  }
}

.btn-logo{
  background: $white;
  margin: 0 !important;
  img{
    width: 100%;
    height: auto;
    max-width: 80px;
    object-fit: contain;
  }

}

.btn-warning{
  background-color:$purple !important;
  border-color:$purple !important;
}


.footer{
  background: $purple !important;
}

.footer{
  .hipogest img{
    width: 100%;
    height: auto;
    max-width: 50px;
    object-fit: contain;
  }
}

.red {
  color: $purple;
}

.blue {
  color: $purple;
}

.orange {
  color: $purple;
}

.yel {
  color: $purple;
}

.btn-orange {
  background: $purple;
  color: $white;
  &:hover {
    opacity: 0.8;
    color: $white;
  }
}

.btn-yel {
  background: $purple;
  color: $white;
  &:hover {
    opacity: 0.8;
    color: $white;
  }
}

.btn-green {
  background:$purple;
  color: $white;
  &:hover {
    opacity: 0.8;
    color: $white;
  }
}

/*-- Banner Carousal
===================== --*/
.carousel-caption {
  h2 {
    font: 40px $font-montserrat;
    margin-bottom: 18px;
    color: $white;
  }
  h3 {
    font-size: 23px;
    margin: 0 0 50px;
    min-height: 45px;
  }
}

.banner-slider {
  overflow: hidden;
  border-bottom: 10px solid #3a3a3c;
  .carousel-control {
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
    margin-top: -40px;
    opacity: 1;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 80px;
    z-index: 99;
    span.fa {
      color: $white;
      font-size: 35px;
      position: relative;
      top: 22px;
    }
  }
  .carousel-inner {
    > .item:before {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  .left.carousel-control {
    left: -120px;
  }
  .right.carousel-control {
    right: -120px;
  }
  .carousel-caption {
    bottom: 18%;
    left: 10%;
    right: 10%;
    h2 {
      font: 40px $font-montserrat;
      color: $white;
    }
    h3 {
      font-size: 23px;
    }
  }
  &:hover {
    .carousel-control {
      transition: all 0.5s linear 0s;
    }
    .left.carousel-control {
      left: 0px;
    }
    .right.carousel-control {
      right: 0px;
    }
  }
}

/*-- Car List
================= --*/
.car-list {
  text-align: center;
  li {
    margin-bottom: 50px;
    h3 {
      font: normal 24px $font-montserrat;
      max-width: 76%;
      color: $black-3b;
      margin: 34px auto;
    }
    p {
      max-width: 82%;
      margin: auto;
      line-height: 24px;
    }
    & > figure:first-child {
      height: 177px;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }


    figure.mt50 {
      height: 60px;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    figure {

      img {
        transition: all .5s linear;
        &:hover {
          -webkit-transform: translate(-50%, -50%) scale(1.05);
          transform:translate(-50%, -50%) scale(1.05);
        }
      }
    }
    &:after {
      background: #e8e8e8;
      bottom: 0;
      content: "";
      height: 60%;
      position: absolute;
      right: 0;
      top: 145px;
      width: 2px;
    }
    &:nth-of-type(3n+3):after {
      display: none;
    }
  }
}

.building-list {
  .car-list {
    li {
      margin-bottom: 5px;
      h2 {
        margin: 16px auto 34px;
      }
      figure.space {
        margin: 34px 0px 58px;
      }
    }
  }
}

.food-list {
  .car-list {
    li {
      margin-bottom: 5px;
      h2 {
        margin: 20px auto 34px;
      }
      figure.space {
        margin: 32px 0px 42px;
      }
    }
  }
}

/*-- Middle Banner
==================== --*/
.middle-banner,
.motor-banner,
.invest-banner,
.construct-banner,
.food-banner {
  background: url(../images/servicos_list/girl-car.jpg) center no-repeat;
  padding: 50px 0px 100px;
  h2 {
    font: 33px $font-montserrat-bold;
    color: $black-3b;
  }
  h3 {
    max-width: 480px;
    font-size: 23px;
    line-height: 30px;
  }
}

.motor-banner {
  background: url(../images/automoval/hp-man.jpg) center no-repeat;
}

.main-content {
  padding: 38px 0px 56px;
  h2 {
    font: 33px $font-montserrat-bold;
    color: $black-3b;
  }
  h3 {
    font-size: 23px;
    line-height: 30px;
  }
}

.food-banner {
  background: url(../images/alimentar/chef.png) center no-repeat;
}

.invest-banner {
  background: url(../images/ogurupo/gilrs-mountain.jpg) center no-repeat;
}

.construct-banner {
  background: url(../images/imobiliaria/engineers.png) center no-repeat;
}

/*-- Vehicle Details
===================== --*/
.vehicle-details {
  > li {
    padding: 10px 15px;
    margin-bottom: 10px;
    min-height: 276px;
    .info-bg {
      .vehicle-img {
        position: relative;
        padding: 0;
        figure {
          overflow: hidden;
          img {
            transition: all .5s linear;
            &:hover {
              -webkit-transform: scale(1.05);
              transform: scale(1.05);
            }
          }
        }
        a {
          position: absolute;
          top: 0;
          right: -14px;
          width: 45px;
          height: 45px;
          padding: 0px;
          img {
            padding-top: 10px;
          }
        }
      }
    }
    h3 {
      font: 29px $font-montserrat-bold;
      color: $black-3b;
      margin-top: 45px;
      text-align: left;
    }
    ol {
      list-style: square;
      font-size: 16px;
      font-weight: 600;
      margin-top: 17px;
      padding-left: 20px;
      li {
        margin-bottom: 7px;
        font-weight: normal;
      }
    }
  }
}

/*-- Equipment
================= --*/
.equip-list {
  h2 {
    font: 34px $font-montserrat-bold;
  }
  p {
    font: 18px $font-montserrat;
    color: $grey-94;
  }
  ul {
    margin-top: 95px;
    li {
      margin-bottom: 40px;
      figure {
        overflow: hidden;
        img {
          transition: all .5s linear;
          &:hover {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.equip-content {
  margin-top: 27px;
  p {
    font: 500 17px/28px $font-roboto;
    margin-bottom: 24px;
    margin-left: 50px;
  }
  .row {
    margin: 76px 0 40px;
    figure {
      position: relative;
      padding-right: 50px;
      margin-right: 28px;
      &:after {
        background: #e8e8e8;
        bottom: 0;
        content: "";
        height: 170%;
        position: absolute;
        right: 0;
        top: -23px;
        width: 2px;
      }
    }
    button {
      margin: 5px 8px;
    }
  }
}


.footer ul li h4{color:$white;}

.footer ul li ul li a:hover{color: $white !important;}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    right: 20%;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}
