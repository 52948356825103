/* Medium Devices, Desktops */
@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1024px) and (max-width: 1200px) {

}

@media screen and (max-width: 991px) {

}
@media screen and (max-width: 1025px) {

  .car-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    li {

      &:before {
        content: none;
      }
    }
  }


  .go-up-button {
    height: 35px;
    width: 35px;
    bottom : 10px;
    i {
      font-size: 13px;
      line-height: 35px;
    }
  }

}

/* Small Devices, Tablets */
@media screen and (max-width: 768px) {





  .go-up-button {
    height: 30px;
    width: 30px;
    bottom : 10px;
    i {
      font-size: 13px;
      line-height: 30px;
    }
  }

  nav {
    &.navbar-fixed-top {
      min-height: initial;
    }
  }
  .main-header {
    display: flex;
    align-items: center;
    height: 50px;
    button {
      margin-top: 0px;
    }
    ul {
      margin: 0px;
      &.list-inline {

        li:first-child {
          display: none;
        }
        li:last-child {
          display: none;
        }
      }
    }
    .navbar-nav > li > a {
      padding-top: 10px;
    }
  }
  .middle-banner, .motor-banner, .invest-banner, .construct-banner, .food-banner {
    text-align: center;
  }
  .middle-banner h2, .motor-banner h2, .invest-banner h2, .construct-banner h2, .food-banner h2 {
    text-align: center;
  }

  .middle-banner h3, .motor-banner h3, .invest-banner h3, .construct-banner h3, .food-banner h3 {
    text-align: center;
  }

  .vehicle-details > li .info-bg {
    height: initial;

  }


  .info-wrapper p {
    margin-left: 0px;
  }

  .vehicle-details > li .info-bg h3:after {
    width: 90%;
  }

  .banner-slider .carousel-caption h4 {
    font: 30px "Montserrat", Arial, sans-serif;
    color: #fff;
    margin-bottom: 32px;
  }

  .banner-slider {
    .carousel-inner {
      .item {
        .carousel-caption h4 {
          font: 30px "Montserrat", Arial, sans-serif;
          color: #fff;
          margin-bottom: 32px;
        }
        &.services {
          .carousel-caption h4 {
            font: 25px "Montserrat", Arial, sans-serif;
          }
        }
      }
    }
  }

  .head-logo {
    img {
      margin: 0 auto;
    }
  }

  .middle-banner, .motor-banner, .invest-banner, .construct-banner, .food-banner {
    &:before {
      content: '';
      background-color: rgba(white, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    & > .container {
      z-index: 2;
      position: relative;
    }
  }

  .banner-slider {
    .carousel-inner {
      .item {
        figure {
          height: 30vh;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }



  .vehicle-details>li .info-bg .vehicle-img .vehicle-icon{
    right:0px ;
  }

  .page-who{
    .info-wrapper .row {
      margin: 10px 0 0px;
    }

    #mapa1{
      height: 300px;
    }

    .hipo-contacts{
      p{
        font-size: 14px;
        line-height: normal;
      }
    }
    .banner-slider .carousel-inner .item figure{
      max-height: 100px;
    }
  }

  .car-list {
    li {
      margin-bottom: 40px!important;
      p{

      }
      h3 {

        font: normal 20px "Montserrat",Arial,sans-serif;
      }
    }
  }

  .page-alimentar{
    .info-wrapper{
      h2{
        margin-left: 0!important;
        font-size: 25px;
      }
    }
  }

  .food-banner{
    background-position: 56% 5%;
    //background-size: 180%;
    background-size: cover;
    h3{
      margin: 20px auto 40px auto;
    }
  }
  .motor-banner{
    background-position: 65% 20%;
    //background-size: 180%;
    background-size: cover;
    h3{
      margin: 20px auto 40px auto;
    }
  }

  .middle-banner{
    background-position: 52% 31%;
    background-size: 130%;
    h3{
      margin: 20px auto 40px auto;
    }
  }

  .construct-banner{
    background-size: cover;
    h3{
      margin: 20px auto 40px auto;
    }
  }

  .transports{
    figure.mb50{
      margin-bottom: 0;
    }
  }

  .carousel-indicators {
    bottom: 0px;
  }

}

/* Extra Small Devices, Phones */

@media screen and (max-width: 670px) {
  .banner-slider {
    .carousel-inner {
      .item {
        figure {
          height: 100vh;
        }
      }
    }
  }

  .car-list.automovel li{
    h3{
      height: auto;
    }
  }
}

@media screen and (max-width: 420px) {


  .footer {
    .hipogest {
      & > img {
        display: block;
        margin: 0 auto 10px auto;
      }
    }
    .webcomum {
      a {
        display: block;
        margin: 10px auto 0 auto;
      }
    }
  }

  .banner-slider {
    .carousel-inner {
      .item {
        figure {
          height: 80vh;
        }
      }
    }
  }

  .construct-banner{
    background-position: 90% 100%;
    background-size: cover;
  }

  .food-banner{
    background-position: 66% 100%;
    //background-size: 500%;
    background-size: cover;

  }
  .motor-banner{
    background-position: 66% 100%;
    //background-size: 500%;
    background-size: cover;
  }

  .middle-banner{
    background-position: 70% 31%;
    //background-size: 500%;
    background-size: cover;

  }


}

@media screen and (max-width: 360px) {




}

@media screen and (max-width: 330px) {

  .banner-slider .carousel-inner .item.services .carousel-caption h4{
    font: 22px "Montserrat", Arial, sans-serif;
  }

  .carousel-indicators{
    bottom : 0px;
  }
}