/*-- Footer
================= --*/
.footer {
	ul {
		li {
			max-width: 225px;
    		vertical-align: top;
    		width: 100%;
			margin-bottom:50px;
			h4 {
				color:$red-ef;
				font:18px $font-montserrat-bold;
			}
			ul {
				margin-top:20px;
				li {
					width:100%;
					margin-bottom:7px;
					a {
						font-size:15px;
						color:#c8c9cb;
						transition:all 0.5 linear;
						&:hover, &:focus {
							color:$green-2b;
							margin-left:7px;
						}
					}
				}
			}
		}
	}
	span {
		color:$grey-81;
		font-size:15px;
		padding:0px 15px;
	}
}