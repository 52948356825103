/* Medium Devices, Desktops */
@media screen and (min-width:768px) {
	.navbar .header {
		display:block;
	}
}
@media screen and (min-width:1024px) and (max-width:1200px) {
	.vehicle-details > li h3 {
		margin-top:15px;
		margin-left:7px;
	}
	.car-list li h3 {
		font-size:20px;
	}
	.footer ul li {
		margin-bottom:0;
		max-width:190px;
	}
}

@media screen and (max-width:991px) {	
	.header {
		.mt20 {
			margin-top:14px;
		}
		ul li {
			margin:0px 12px;
		}
	}
	.navbar-nav {
		text-align:center;
		margin:auto;
	}
	.car-list li {
		border-bottom:2px solid #e8e8e8;
		padding-bottom:20px;
		h2, p {
			max-width:100%;
		}
	}
	.car-list li:after {
		display:none;
	}
	.middle-banner,
	.motor-banner,
	.invest-banner,
	.construct-banner,
	.food-banner {
		padding:0px;
	}
	.navbar-fixed-top {
		position:static;
	}
	.vehicle-details > li {
		width:100%;
		padding-left:15px;
		padding-right:15px;
		.info-bg {
			text-align:center;
			.vehicle-img {
				figure img {
					width:100%;
				}
				img {
					margin:auto;
				}
				a {
					margin-top:0px;
				}
			}
			ol {
				text-align:left;
			}
		}
	}
	
}

/* Small Devices, Tablets */
@media screen and (max-width:767px) {
	.header ul li {
		width:100%;
		margin:0px;
	}
	.navbar {
		.header {
			display:none;
		}
	}
	.main-header {
				.navbar-nav > li > a {
					padding-top:15px;
				}
		}
	.vehicle-details > li {
		margin-bottom:0;		
		h3 {
			margin-top:30px;
		}
	}
	.list-inline.car-list > li,
	.equip-list ul li {
		padding-left:15px;
		padding-right:15px;
	}
	.equip-content p {
		margin-left:0px;
	}
	.list-inline.car-list > li {
		padding-bottom:40px;
	}
	.navbar-default {
		.navbar-nav .open .dropdown-menu {
			background:#444;
			position:absolute;
			li > a:hover {
				color:#fff;
			}
		}
	}
	.navbar-nav > li > .dropdown-menu::after {
		border-bottom:8px solid #444;
	}
	.footer {
		 ul.list-inline > li {
			max-width:100%;
			padding:0 15px;
			margin-bottom:3px;
			ul {
				margin-top:0px;
				margin-bottom:15px;
			}
		}
		.pull-left.mt50,
		.pull-right.mt50 {
			margin-top:10px;
			text-align:center;
			float:none;
			width:100%;
		}
		.mobile-menu > li {
			.mainmenu-child {
				border-bottom:1px solid #666;
			}
			display:block;
			h4 {
				cursor:pointer;
				margin:0;
				padding:12px 0;
			}
			h4:after,
			&.active h4:after {
				content:'\f078';
				color:#848585;
				font-family:'fontawesome';
				float:right;
			}
			&.active h4:after {
				content:'\f077';
			}
			.mainmenu-sub {
				display:none;
			}
		}
	}
}

/* Extra Small Devices, Phones */
@media screen and (max-width: 550px){	
	.vehicle-details > li {		
		h3 {
			margin-top:30px;
		}
		.info-bg {
			.col-xs-6 {
				width:100%;
			}
			.vehicle-img a {
				right:0;
			}
			ol {	
				padding-bottom:20px;
			}
		}
	}
}

@media screen and (max-width:420px) {
	.main-header {
		button {
			img {
				max-width:85%;
			}
		}
		ul {
			padding:0px;
			margin:7px 0 0;
			.head-logo {
				padding:3px;
				margin:0;
				img {
					max-width:75%;
				}
			}
			a {
				font-size:12px;
				img {
					max-width:86%;
				}
			}
		}
	}
	.middle-banner .mb50,
	.motor-banner .mb50,
	.invest-banner .mb50,
	.construct-banner .mb50,
	.food-banner .mb50 {
		margin-bottom:27px;		
	}
} 

@media screen and (max-width:360px) {
	.main-header ul.col-sm-9 {
		width:78%;
	}
}

@media screen and (max-width:320px) {
	.main-header ul.col-sm-9 {
		width:75%;
	}
}